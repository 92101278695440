h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

a {
  color: #0a66c2;
  text-decoration: none;
}

/* Base Layout */
.App {
  display: grid;
  grid-template-areas: 'leftbar content rightbar';
  grid-template-columns: auto 1fr auto; /* Left Sidebar | Content | Right Sidebar */
  height: 100vh;
  width: 100vw;
}

/* Center Content Layout */
.content {
  grid-area: content; /* Matches grid layout */
  max-width: calc(100% - 40px); /* Maintains 20px gutters */
  padding: 0 20px;
  transition:
    margin 0.4s ease-in-out,
    width 0.4s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers content */
  justify-content: center;
}

/* Adjust content margins dynamically */
.content.no-sidebars {
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - 40px); /* Maintain 20px gutter */
}

.content.left-open {
  margin-left: 220px; /* Left sidebar width + 20px gutter */
  padding: 0 40px;
  /*width: calc(100% - 40px); */
}

.content.right-open {
  margin-right: 320px; /* Right sidebar width + 20px gutter */
  padding: 0 40px;
  width: calc(100% - 40px);
}

/* Content Shift for Both Sidebars */
.content.both-open {
  margin-left: 220px;
  margin-right: 320px;
  padding: 0 40px;
  width: calc(100% - 40px);
}

/* Sidebar Base Styles */
.leftbar,
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  transition:
    transform 0.4s ease-in-out,
    width 0.4s ease-in-out;
  box-shadow: none; /* Removes weird border on close */
}

/* Left Sidebar */
.leftbar {
  grid-area: leftbar;
  width: 200px;
  /* background-color: #4cb7e3; */
  background: linear-gradient(90deg, #81c69f, #87c99b, #c9e7e5);
  color: white;
  padding: 20px;
  left: 0;
  transform: translateX(-100%);
  transition: transform 0.4s ease-in-out;
  z-index: 2;
}

.leftbar.active {
  transform: translateX(0); /* Slide in from left */
}

/* Right Sidebar */
.sidebar {
  grid-area: rightbar;
  width: 300px;
  /* background-color: #8aca96; */
  background: linear-gradient(270deg, #81c69f, #87c99b, #c9e7e5);
  color: white;
  padding: 20px;
  right: 0;
  transform: translateX(100%);
  transition: transform 0.4s ease-in-out;
  position: fixed;
  z-index: +1;
}

.sidebar.active {
  transform: translateX(0);
}

/* Sidebar Toggle Buttons */
.hamburger,
.sidebar-toggle {
  font-weight: bold;
  cursor: pointer;
  z-index: 3;
  background: white;
  color: #4a5d4a;
  position: fixed;
  font-size: 28px;
  padding: 5px 10px;
  margin: 5px;
  border-radius: 5px;
  transition:
    transform 0.3s,
    background 0.3s;
  border: 1px solid #4bc8f4;
}

.hamburger {
  top: 10px;
  left: 15px;
}

.sidebar-toggle {
  top: 10px;
  right: 15px;
}

.sidebar-toggle:hover,
.hamburger:hover {
  background: #ddd;
}

/* Center Logo */
.App-header {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  width: auto;
  z-index: 1000;
}

.main-content {
  margin-top: 130px;
}

/* Close Button */
.close-btn {
  background: none;
  color: white;
  border: none;
  position: fixed;
  top: 0px;
  left: 15px;
  font-size: 28px;
  padding: 5px 10px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.chatbox {
  height: 300px;
  overflow-y: auto;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  background-color: #fff;
}

.message.user {
  text-align: right;
  color: #4f4f4f;
}

.message.bot {
  text-align: left;
  color: #333;
}

.chat-input {
  width: calc(100% - 87px);
  padding: 10px;
  margin-right: 5px;
}

.chat-button {
  padding: 10px 20px;
}

.logo-container img.sidebar-logo {
  height: 100%;
  width: 100%;
  margin: 5px 0;
  padding: 5px 0;
}

.colored-title {
  color: #56755d;
}

/* Forms */
.login-wrapper,
.reset-password-wrapper,
.register-wrapper,
.upload-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-container,
.reset-password-container,
.register-container,
.upload-container {
  background: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 320px;
}

.input-container {
  margin-bottom: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.content input,
.content select {
  width: 100%;
  max-width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
}

.forgot-password {
  display: block;
  margin-top: 10px;
  color: #0a66c2;
  text-decoration: none;
}

.login-wrapper .register {
  margin-top: 15px;
}

.message {
  margin-top: 10px;
  color: green;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.success-message {
  color: green;
  margin-top: 10px;
}

.register-wrapper .login-link {
  margin-top: 15px;
}

nav ul {
  list-style: none;
  justify-content: center;
  padding: 20px 2px;
  margin-top: 50px;
}

nav ul li {
  /* margin: 0 5px; */
  margin: 10px 0px;
}

nav ul li a,
nav ul li button {
  text-decoration: none;
  color: #fff;
  font-weight: bold;
  background: none;
  border: none;
  cursor: pointer;
}

/* Toggle col ui */
.data-display-container {
  padding: 1rem;
}

.toggle-button {
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  background-color: #4f4f4f;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.toggle-button:hover {
  background-color: #0056b3;
}

.table-container {
  overflow-x: auto; /* Enable horizontal scrolling for remaining columns */
  max-width: 100%;
  border: 1px solid #ccc;
}

.data-table-container {
  flex: 1; /* Allow the table container to grow and fill the available space */
  overflow-y: scroll; /* Enable vertical scrolling for the data table */
  overflow-x: auto; /* Enable horizontal scrolling if needed */
  /* height: 100vh; */
  height: 550px;
  border-top: 2px solid #fff;
}

.data-table {
  /* width: 100vw; */
  border-collapse: collapse;
  table-layout: auto; /* Allow dynamic column sizing */
}
.eighty-vw {
  width: 80vw; /* No need for table to be 100 */
}

.data-table th,
.data-table td {
  padding: 10px;
  margin: 10px;
  text-align: left;
  border: 1px solid #ddd;
  width: 100vw;
}

.data-table th {
  position: sticky; /* Sticky positioning for the header */
  top: 0; /* Stick to the top of the scrollable container */
  background-color: #4f4f4f; /* Background color for the header */
  color: white; /* Header text color */
  z-index: 1; /* Ensure the header stays on top */
}

.data-table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

.data-table tbody tr:nth-child(odd) {
  background-color: #f2f2f2; /* Light grey for odd rows */
}

.data-table tbody tr:nth-child(even) {
  background-color: #e0e0e0; /* Darker grey for even rows */
}

/* Button */
.blue-pill-button,
.login-button,
.reset-button,
.register-button,
.upload-button {
  background-color: #4bc8f4; /* Logo Blue */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px; /* Space between text and icon */
  transition: background-color 0.3s ease;
}

.blue-pill-button:hover {
  background-color: #56755d; /* Logo dark Green on hover */
}

.blue-pill-button svg {
  width: 18px;
  height: 18px;
  fill: white; /* Ensure the icon is white */
}

.clear-chat-button {
  background-color: darkred;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: background-color 0.3sease;
  margin-top: 10px;
}

.clear-chat-button:hover {
  background-color: firebrick;
}

.password-actions {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.password-actions button {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

/* Need to organize this */
/* Need to organize this */
/* Need to organize this */
.processing-container {
  text-align: center;
  padding: 2rem;
}

.progress-bar-wrapper {
  width: 100%;
  height: 16px;
  background-color: #eee;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 1rem;
}

.progress-bar {
  height: 100%;
  background-color: #4caf50;
  width: 0;
  transition: width 0.5s ease;
}

.mapping-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.mapping-container table {
  width: 100%;
  border-collapse: collapse;
}

.mapping-container th,
td {
  padding: 10px;
  border: 1px solid #ddd;
}

.mapping-container select {
  width: 100%;
}
